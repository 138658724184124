<template>
  <div class="header-wrap">
    <NavPC v-if="!isMobile" :handle-logout="handleLogout"></NavPC>
    <NavMobile v-else :handle-side-bar="sideBarOpen"></NavMobile>

    <!-- 側欄 -->
    <SideBar ref="compSideBar" ></SideBar>
  </div>
</template>

<script setup>
import {ref,computed} from 'vue'
import NavPC from "@/components/Header/NavPC.vue";
import NavMobile from "@/components/Header/NavMobile.vue";
import {useGoTo} from "@/composables/useGoTo";
import {useUserStore} from "~/stores/user.ts";
import {useSettingStore} from "@/stores/setting.js";
import SideBar from "@/components/MenuNav/SideBar.vue";

const {go} = useGoTo()

const settingStore = useSettingStore()
const userStore = useUserStore()
// const isMobile = computed(() => settingStore.isMobile);
const {isMobile} = useDevice();
const compSideBar = ref(null);

async function handleLogout() {
  await userStore.logout()
  go('/')
}


// 側欄開關
const sideBarOpen = () => {
  compSideBar.value?.drawerToggle()
}
</script>

<style scoped
       lang="scss">
.header-wrap {
  @apply border-b
  mobile:bg-osn-yellow-00;



}

</style>
