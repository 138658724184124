<template>
  <ul class="h-[66px] flex items-center pc:mx-[100px]">
    <li @click="goHome({})">
      <!--      <img class="h-[30px]"
                 src="@/assets/image/logo/osn-zh-horizon.png"
                 alt="logo"
            />-->
      <NuxtImg format="webp"
               loading="lazy"
               height="30"
               src="/logo/osn-zh-horizon.png"
               alt="logo"/>
    </li>
    <li class="grow"></li>
    <!--    關鍵字搜尋-->
    <li class="">
      <div class="mx-[15px] leading-[40px] border-b border-osn-grey-03 text-[14px] text-osn-grey-02 flex items-center">
        <input type="text"
               class="outline-none"
               v-model="searchKeyword"
               :placeholder="$t('placeholder.keyword')"
               @keyup.enter="handleSpaceKeywordSearch()"
               ref="searchInput">
        <img class="search-btn"
             src="@/assets/image/icon/menu/manage_search.svg"
             @click="handleSpaceKeywordSearch()"
             alt="">
      </div>
    </li>
    <li>
      <NuxtLink to="/blog"
                class="pc:mr-[16px] underline underline-offset-2">
        {{ $t('article.space_article') }}
      </NuxtLink>
    </li>
    <li>
      <NuxtLink to="/host/homepage"
                class="pc:mr-[16px] underline underline-offset-2">
        {{ $t('footer.owner_function') }}
      </NuxtLink>
    </li>
    <li>
      <NuxtLink to="/host/plan"
                class="pc:mr-[16px] underline underline-offset-2">
        {{ $t('footer.owner_plan') }}
      </NuxtLink>
    </li>
    <li class="mr-[6px] px-[24px] flex items-center h-[40px] leading-[40px] bg-osn-black-00 text-osn-yellow-00 rounded-full text-[14px] font-semibold"
        @click="toWebsite(backstageUrl)">
      {{ $t('menu.i_am_owner') }}
      <!--      <img src="../../assets/image/icon/svg/open_in_new.svg"-->
      <!--           alt="">-->
      <svg-icon name="open_in_new"
                size="20"
                class="ml-[6px]"></svg-icon>
    </li>
    <li v-if="!isLogin"
        class="px-[24px] h-[40px] leading-[40px] bg-osn-yellow-00 rounded-full text-[14px] font-semibold"
        @click="handelLoginBtn">{{ $t('menu.login_reservation') }}
    </li>

    <!--    登入後-->

    <li v-if="isLogin"
        class="flex items-center mr-2"
        @click="go('/account')">
      <img class="w-[20px] h-[20px] mr-[6px]"
           src="@/assets/image/icon/menu/account_circle.svg"
           alt="">
      {{ $t('menu.account_manager') }}
    </li>
    <li v-if="isLogin"
        class="flex items-center mr-2"
        @click="go('/order')">
      <img class="w-[20px] h-[20px] mr-[6px]"
           src="@/assets/image/icon/menu/list_alt.svg"
           alt="">
      {{ $t('menu.check_order') }}
    </li>
    <li v-if="isLogin"
        class="flex items-center mr-5"
        @click="handleLogout">
      <img class="w-[20px] h-[20px] mr-[6px]"
           src="@/assets/image/icon/menu/logout.svg"
           alt="">
      {{ $t('menu.logout') }}
    </li>
  </ul>
</template>
<script setup>
import {computed, nextTick, onMounted, ref} from 'vue'
import {useGoTo} from "@/composables/useGoTo";
import {useUserStore} from "~/stores/user";
import {useSearchQueryStore} from "~/stores/searchQuery";
import {useSearchWithStore} from "~/composables/useSearchWithStore";


const userStore = useUserStore()
const {go, toWebsite, goHome, handelLoginBtn} = useGoTo()
const isLogin = computed(() => userStore.getToken);
const backstageUrl = import.meta.env.VITE_APP_BASE_BACKSTAGE_URL + 'login'
const searchQueryStore = useSearchQueryStore()
const {handleSearchWithStore} = useSearchWithStore()
const searchKeyword = ref('');
const searchInput = ref(null)

const props = defineProps({
  handleLogout: {
    type: Function
  }
})

onMounted(() => {
  try {
    const keywordStore = searchQueryStore.getSearchKeyword;
    searchKeyword.value = keywordStore || '';

  } catch (error) {
    console.error('Error setting SearchBar.vue initial values:', error);
  }
})

function handleSpaceKeywordSearch() {
  if (!searchKeyword.value) {
    return
  }
  searchQueryStore.initQueryDate()
  searchQueryStore.setKeyword(searchKeyword.value)
  handleSearchWithStore()
  go('/search-result')
  // searchQueryStore.clearKeyword()
}


</script>
<style scoped
       lang="scss">
li {
  @apply text-[16px] cursor-pointer;

}

</style>
