<template>
  <div>
    <el-drawer
        v-model="drawer"
        :show-close="false"
        :with-header="false"
        direction="ltr"
        :size="drawerSize"
        class="side-menu"
    >
      <SideBarContent :handle-drawer-close="handleDrawerClose"></SideBarContent>
    </el-drawer>
  </div>
</template>
<script setup>
import {computed, onMounted, ref} from 'vue'
import {useUserStore} from "~/stores/user.ts";
import {useSettingStore} from "@/stores/setting.js";
import {useGoTo} from "@/composables/useGoTo";
import SideBarContent from "@/components/MenuNav/SideBarContent.vue";


const drawer = ref(false)
const drawerSize = ref('400px')
const userStore = useUserStore()
const settingStore = useSettingStore()
const isMobile = computed(() => settingStore.isMobile);
const {go} = useGoTo()

defineExpose({
  drawerToggle
});

onMounted(async () => {

  if (isMobile.value) {
    drawerSize.value = '88%'
  } else {
    drawerSize.value = '400px'
  }
})

function drawerToggle() {
  drawer.value = !drawer.value
}

function handleDrawerClose() {
  drawer.value = false
}
</script>
<style scoped
       lang="scss">
:deep(.side-menu) {
  .el-drawer__body{
    @apply px-0 py-0;
  }
}
</style>
