<template>
  <div class="side-bar-content-wrap">
    <!-- 側欄 header-->
    <div class="side-bar-header">
      <div class="logo-title">
        <img src="../../assets/image/logo/osn-zh-horizon.png"
             alt=""/>
      </div>
      <div v-if="!isLogin"
           class="flex justify-center">
        <div class="user-login-btn"
             @click="handelLoginBtn">{{ $t('menu.login') }}
        </div>
        <div class="owner-login-btn"
             @click="toWebsite(backstageLoginUrl)">{{ $t('menu.owner_login') }}
        </div>
      </div>
      <div v-else class="pb-7 border-b border-[#C3C3C1]">
        <div class="login-wrap">
          <div>{{ $t('menu.welcome') + name }}</div>
          <div @click="handleLogout">
            <img src="@/assets/image/icon/menu/logout.svg"
                                          alt="">
          </div>
        </div>
        <div class="owner-login-btn"
             @click="toWebsite(backstageLoginUrl)">{{ $t('menu.owner_login') }}
        </div>
      </div>

    </div>
    <!--側欄內容-->
    <ul class="side-bar-body">
      <li v-if="isLogin">
        <NuxtLink to="/account">
          <img src="@/assets/image/icon/menu/account_circle.svg"
               alt="">
          {{ $t('menu.account_manager') }}
        </NuxtLink>
      </li>
      <li v-if="isLogin">
        <NuxtLink to="/order">
          <img src="@/assets/image/icon/menu/list_alt.svg"
               alt="">
          {{ $t('menu.check_order') }}
        </NuxtLink>
      </li>
      <li>
        <NuxtLink to="/blog"
                  target="_blank">
          <img src="@/assets/image/icon/menu/article.svg"
               alt="">
          {{ $t('article.space_article') }}
        </NuxtLink>
      </li>
      <li>
        <NuxtLink to="/host/homepage"
                  target="_blank">
          <img src="@/assets/image/icon/menu/policy.svg"
               alt="">
          {{ $t('menu.function_introduction') }}
        </NuxtLink>
      </li>
      <li>
        <NuxtLink to="/host/plan"
                  target="_blank">
          <img src="@/assets/image/icon/menu/privacy.svg"
               alt="">
          {{ $t('menu.owner_index') }}
        </NuxtLink>
      </li>
      <li>
        <NuxtLink :to="lineUrl"
                  target="_blank">
          <img src="@/assets/image/icon/menu/line.svg"
               alt=""
               width="24"
               height="24">
          {{ $t('menu.line') }}
        </NuxtLink>
      </li>
      <li>
        <NuxtLink to="/host/qa"
                  target="_blank">
          <img src="@/assets/image/icon/menu/info.svg"
               alt="">
          {{ $t('menu.help_center') }}
        </NuxtLink>
      </li>
    </ul>
    <!-- 側欄 footer-->
    <div class="side-bar-footer">
      <ul>
        <li>
          <NuxtLink to="/host/homepage"
                    target="_blank">
            {{ $t('menu.have_space') }}
          </NuxtLink>
        </li>
        <li>
          <NuxtLink to="/host/homepage"
                    target="_blank">
            {{ $t('menu.join_us') }}
            <img src="../../assets/image/icon/svg/arrow_circle_right.svg"
                 alt="">
          </NuxtLink>
        </li>
        <li>Copyright © 2024 One Step Technology Ltd.</li>
        <li>Powered by OneStep Version 1.0</li>
      </ul>

    </div>
  </div>

  <el-dialog
      v-model="loginDialogVisible"
      width="30%"
      :show-close="false"
      align-center
  >
    <LoginLayout @onCloseLoginDialog="handelLoginDone"></LoginLayout>
  </el-dialog>
</template>
<script setup>
import {computed, ref} from "vue";
import {useGoTo} from "@/composables/useGoTo";
import {useUserStore} from "~/stores/user.ts";
import {useSettingStore} from "@/stores/setting.js";
import LoginLayout from "@/components/LoginLayout.vue";

const props = defineProps({
  handleDrawerClose: {
    type: Function
  }
})

const userStore = useUserStore()
const settingStore = useSettingStore()
const isMobile = computed(() => settingStore.isMobile);
const isLogin = computed(() => userStore.getToken);
const name = computed(() => userStore.getName);
const {go, toWebsite} = useGoTo()
const loginDialogVisible = ref(false)

// 外連 url
const hostUrl = ref(import.meta.env.VITE_APP_BASE_HOST_URL)
const faceBookUrl = ref(import.meta.env.VITE_APP_BASE_FACEBOOK_URL)
const lineUrl = ref(import.meta.env.VITE_APP_BASE_LINE_URL)
const helpUrl = ref(import.meta.env.VITE_APP_BASE_HELP_URL)
const clientUrl = ref(import.meta.env.VITE_APP_BASE_CLIENT_URL)
const privacyUrl = ref(import.meta.env.VITE_APP_BASE_PRIVACY_URL)
const backstageLoginUrl = import.meta.env.VITE_APP_BASE_BACKSTAGE_URL + 'login'

async function handleLogout() {
  await userStore.logout()
}

function handelLoginBtn() {
  if (isMobile.value) {
    go('/login')
  } else {
    loginDialogVisible.value = true
  }
}

function handelLoginDone() {
  loginDialogVisible.value = false
  props.handleDrawerClose()
}
</script>
<style scoped
       lang="scss">
.side-bar-content-wrap {
  @apply px-[30px] py-0 flex flex-col h-full;
  .side-bar-header {
    @apply text-os-gray-900 px-0 pt-[87px] mb-0;
    .logo-title {
      @apply flex justify-center items-center;

      img {
        @apply mb-[36px]
        md:max-h-[70px]
        max-h-[50px];

      }
    }


    .user-login-btn, .owner-login-btn {
      @apply w-[131px] h-[44px] leading-[44px] text-center rounded-full text-[14px] font-semibold cursor-pointer;
    }

    div.user-login-btn {
      @apply bg-osn-yellow-00 text-osn-black-00 mr-[8px];
    }

    div.owner-login-btn {
      @apply bg-osn-black-00 text-osn-yellow-01;
    }


    .login-wrap {
      @apply flex text-osn-grey-00 text-[18px] items-center justify-between pb-3;

      div:nth-child(2) {
        @apply cursor-pointer;
      }
    }


    .el-drawer__close-btn {
      @apply text-4xl absolute top-[64px] right-[30px];
    }
  }

  ul.side-bar-body {
    @apply text-[16px] py-[34px];
    li {
      @apply flex mb-[32px] text-[16px] text-osn-black-00 cursor-pointer;
      a {
        @apply flex items-center;
      }

      img {
        @apply mr-[12px]
      }
    }
  }

  .side-bar-footer {
    @apply mt-auto pt-[30px] pb-[60px] px-0 border-t border-gray-300 text-[14px];

    ul {
      @apply text-left text-[16px] text-osn-black-00;
      li {
        @apply mb-[12px] cursor-pointer;
        a {
          @apply flex items-center;
        }
      }

      li:nth-child(3), li:nth-child(4) {
        @apply text-[12px] text-osn-dark-grey-43
      }
    }
  }
}
</style>
